<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <div class="type-div">
        <label>名称</label>
        <label class="type-input-label">
          <input type="text" v-model="typeName" maxlength="10" placeholder="请输入类型名称">
        </label>
        <input class="type-add-btn" type="button" value="添加类型" @click="onAddType">
      </div>
      <table-box ref="tableBox"  :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
        </template>
        <template #table-body="props">
          <tr>
            <template v-for="(item, index) in tableField">
              <td v-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import TableBox from "../../components/TableBox";
import { postBlueprintTypeAdd, getBlueprintTypeItems } from "@/common/api";

export default {
  components: { TableBox },
  props: {
    layerid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      action: getBlueprintTypeItems,
      typeName: "",
      reloadData: false,
      searchType: "1",
      searchMsg: "",
      queryParams: {
        type: 0,
        msg: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "编号", name: "id", title: "id", isHtml: false, value: this.getObjValue },
        { state: true, alias: "名称", name: "name", title: "name", isHtml: false, value: this.getObjValue }
      ],
      tipMsg: ""
    };
  },
  computed: {
    tableFieldNum() {
      let num = this.isOperation ? 1 : 0;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  methods: {
    getObjValue(item, field) {
      return item[field];
    },
    onReloadData() {
      this.reloadData = true;
    },
    onAddType() {
      if(this.typeName.length < 1){
        this.setTipsMsg("请输入类型名称");
        return;
      }
      if(this.typeName.length > 10){
        this.setTipsMsg("类型名称不能大于10个字符");
        return;
      }

      postBlueprintTypeAdd({name: this.typeName})
        .then(() => {
          this.onReloadData();
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px 20px 10px 20px;
    .type-div {
      text-align: center;
      margin-bottom: 20px;
      .type-input-label {
        margin-left: 5px;
        margin-right: 5px;
        input {
          width: 260px;
          height: 30px;
          border: 1px solid #e5e5e5;
          padding: 0 3px;
        }
      }
      .type-add-btn {
        width: 80px;
        height: 30px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
      }
    }
    .table-sub-head-th {
      .div-btn {
        float: left;
        width: 88px;
        height: 23px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .append-btn {
        margin-left: 15px;
        margin-right: 10px;
      }
    }
  }
}
</style>